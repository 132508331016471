var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card',[_c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title))])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFilters),expression:"!showFilters"}],attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text ",on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"cuenta-corriente-filters-form",attrs:{"transition":"fade-transition","id":"cuenta-corriente-filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"pt-2",attrs:{"label":"Comprobantes desde","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat(
                        _vm.rules.validDate,
                        new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesde = false},"blur":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]},"change":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]}},model:{value:(_vm.fechaDesdeTextField),callback:function ($$v) {_vm.fechaDesdeTextField=$$v},expression:"fechaDesdeTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[(_vm.fechaDesdeTextField = _vm.formatDate(_vm.fechaDesde))]},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"pt-2",attrs:{"label":"Comprobantes hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.required.concat(
                        _vm.rules.validDate,
                        new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                          new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                          'Formato incorrecto'
                      ),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]},"change":function($event){[
                        (_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField)),
                        _vm.changeSaldoHastaLabel(_vm.fechaHastaTextField)
                      ]}},model:{value:(_vm.fechaHastaTextField),callback:function ($$v) {_vm.fechaHastaTextField=$$v},expression:"fechaHastaTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[
                      (_vm.fechaHastaTextField = _vm.formatDate(_vm.fechaHasta)),
                      _vm.changeSaldoHastaLabel(_vm.fechaHastaTextField)
                    ]},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-switch',{attrs:{"label":"Incluir comprobantes relacionados","dense":"","outlined":""},model:{value:(_vm.incluirRelacionados),callback:function ($$v) {_vm.incluirRelacionados=$$v},expression:"incluirRelacionados"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"primary--text ",on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","disabled":!_vm.isFormValid,"type":"submit","form":"cuenta-corriente-filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"compId","no-data-text":"No hay datos para las fechas ingresadas","items":_vm.comprobantesDeuda,"loading":_vm.loading,"calculate-widths":"","loading-text":"Cargando datos"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Listado de comprobantes")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.seeComprobante(item.compId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobante")]),(_vm.modalCmp === true)?_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.modalCmp),callback:function ($$v) {_vm.modalCmp=$$v},expression:"modalCmp"}},[_c('DetalleComprobante',{attrs:{"compId":_vm.compId},on:{"closeModalComprobante":_vm.closeModalComprobante}})],1):_vm._e()],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleComprobantesCancelatorios(
                    item.compId,
                    item.numeroComp
                  )}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.comprobanteCancelatorioIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobantes cancelatorios")]),(_vm.modalComprobanteCancelatorio)?_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.modalComprobanteCancelatorio),callback:function ($$v) {_vm.modalComprobanteCancelatorio=$$v},expression:"modalComprobanteCancelatorio"}},[_c('ComprobantesCancelatorios',{attrs:{"compId":_vm.compId,"comprobantesCancelatorios":_vm.comprobantesCancelatorios,"numeroComprobante":_vm.numeroComprobante},on:{"closeModalComprobantesCancelatorios":_vm.closeModalComprobantesCancelatorios}})],1):_vm._e()],1)]}}],null,true)})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCompsSinRelacionar,"item-key":"compId","no-data-text":"No hay datos para las fechas ingresadas","items":_vm.comprobantesSinRelacionar,"loading":_vm.loading,"calculate-widths":"","loading-text":"Cargando datos"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Listado de comprobantes sin relacionar")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.seeComprobanteSinRelacionar(item.compId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobante")]),(_vm.modalCmpSinRelacionar === true)?_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.modalCmpSinRelacionar),callback:function ($$v) {_vm.modalCmpSinRelacionar=$$v},expression:"modalCmpSinRelacionar"}},[_c('DetalleComprobante',{attrs:{"sinRelacionar":_vm.sinRelacionar,"compId":_vm.compIdSinRelacionar},on:{"closeModalComprobante":_vm.closeModalComprobante}})],1):_vm._e()],1)]}}],null,true)})],1),_c('v-container',{staticClass:"pt-0 mt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.saldosHastaLabel,"dense":"","outlined":"","disabled":""},model:{value:(_vm.totalSaldosHasta),callback:function ($$v) {_vm.totalSaldosHasta=$$v},expression:"totalSaldosHasta"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }