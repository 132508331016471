<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" class="pt-5" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4">
                  <!-- Nombre/s -->
                  <v-col cols="12" sm="6" md="3" class="pb-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Persona o razón social"
                      v-model="nombreAgenteCuenta"
                      autocomplete="off"
                      :rules="
                        nombreAgenteCuenta !== null
                          ? [rules.maxLength(nombreAgenteCuenta, 45)]
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Número de documento -->
                  <v-col cols="12" sm="6" md="3" class="pb-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      label="N° de documento"
                      v-mask="['#.###.###','##.###.###']"
                      v-model="dniAgenteCuenta"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Número de agente -->
                  <v-col cols="12" sm="6" md="3" class="pb-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      v-mask="'#########'"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      label="N° agente de cuenta"
                      v-model="idAgenteCuenta"
                      :rules="
                        idAgenteCuenta !== null
                          ? [rules.maxLength(idAgenteCuenta, 10)]
                          : []
                      "
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>

                  <!-- Número de CUIL/CUIT -->
                  <v-col cols="12" sm="6" md="3" class="pb-0">
                    <v-text-field
                      type="text"
                      outlined
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      dense
                      @change="
                        cuilOrCuit === null || cuilOrCuit === ''
                          ? []
                          : validarCUIL(cuilOrCuit)
                      "
                      label="CUIL/CUIT"
                      v-model="cuilOrCuit"
                      v-mask="'##-########-#'"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-col cols="12" md="8" class="py-0" align="left">
                    <v-subheader>(*) Datos obligatorios</v-subheader>
                  </v-col>
                  <v-col cols="12" md="4" align="end" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="agenteId"
            :items="resultAgentesCuenta"
            class="elevation-1"
            :loading="loading"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- Nuevo -->
                  <v-col cols="2" align="end" class="text-right">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="goToEditAgenteCuenta(null)"
                      v-if="canCreate"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Alicuotas Percepcion -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canOpenAlicuotasPercepcion">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    class="p-1"
                    @click="
                      toggleModalAlicuotaPercibidaAgente(
                        item.agectaId,
                        item.agectaNom
                      )
                    "
                  >
                    {{ percentIcon }}
                  </v-icon>
                </template>
                <span>Alícuotas percepción</span>
              </v-tooltip>

              <!-- Cuenta Corriente -->
              <v-tooltip left v-if="canOpenCuentaCorriente">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      toggleModalCuentaCorrienteAgente(
                        item.agectaId,
                        item.agectaNom
                      )
                    "
                    class="p-1"
                  >
                    {{ cuentaCorrienteIcon2 }}
                  </v-icon>
                </template>
                <span>Cuenta corriente</span>
              </v-tooltip>

              <!-- Formas de pago -->

              <v-tooltip left v-if="canOpenFormasPago">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      toggleModalFormasPagoAgente(item.agectaId, item.agectaNom)
                    "
                    class="p-1"
                  >
                    {{ formaPagoIcon }}
                  </v-icon>
                </template>
                <span>Formas de pago</span>
              </v-tooltip>

              <!-- Grupo Familiar -->

              <v-tooltip left v-if="canOpenGrupoFamiliar">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      toggleModalRelacionGrupoFamiliar(
                        item.agectaId,
                        item.agectaNom
                      )
                    "
                    class="p-1"
                  >
                    {{ grupoFamiliarIcon }}
                  </v-icon>
                </template>
                <span>Relación con el grupo familiar</span>
              </v-tooltip>

              <!-- Editar agente -->

              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToEditAgenteCuenta(item.agectaId)"
                    class="p-1"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar agente de cuenta</span>
              </v-tooltip>

              <!-- Eliminar agente -->

              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteAgente(item.agectaId)"
                    class="p-1"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar agente de cuenta</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <DeleteDialog
          :titleProp="titleDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />

        <!-- Modal Formas de pago -->

        <v-dialog
          v-if="modalFormasPagoAgente"
          v-model="modalFormasPagoAgente"
          @keydown.esc="toggleModalFormasPagoAgente"
          max-width="80%"
          persistent
        >
          <FormasPagoAgente
            :agectaNom="agectaNom"
            :agectaId="agectaId"
            @toggleModalFormasPagoAgente="toggleModalFormasPagoAgente"
          ></FormasPagoAgente>
        </v-dialog>

        <!-- Modal Grupos familiares -->

        <v-dialog
          v-if="modalGrupoFamiliar"
          v-model="modalGrupoFamiliar"
          @keydown.esc="toggleModalRelacionGrupoFamiliar"
          max-width="80rem"
          persistent
        >
          <RelacionesGrupoFamiliar
            :agectaNom="agectaNom"
            :agectaId="agectaId"
            @toggleModalRelacionGrupoFamiliar="toggleModalRelacionGrupoFamiliar"
          ></RelacionesGrupoFamiliar>
        </v-dialog>

        <!-- Modal Cuenta corriente -->

        <v-dialog
          v-if="modalCuentaCorrienteAgente"
          v-model="modalCuentaCorrienteAgente"
          @keydown.esc="toggleModalCuentaCorrienteAgente"
          max-width="80rem"
          persistent
        >
          <CuentaCorrienteAgente
            :agectaNom="agectaNom"
            :agectaId="agectaId"
            @toggleModalCuentaCorrienteAgente="toggleModalCuentaCorrienteAgente"
          ></CuentaCorrienteAgente>
        </v-dialog>
        <!-- Modal Alicuotas percibidas-->

        <v-dialog
          v-if="modalAlicuotaPercibidaAgente"
          v-model="modalAlicuotaPercibidaAgente"
          @keydown.esc="toggleModalAlicuotaPercibidaAgente"
          max-width="60rem"
          persistent
        >
          <AlicuotasPercibidasAgenteCuenta
            :agectaNom="agectaNom"
            :agectaId="agectaId"
            @toggleModalAlicuotaPercibidaAgente="
              toggleModalAlicuotaPercibidaAgente
            "
          ></AlicuotasPercibidasAgenteCuenta>
        </v-dialog>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import validateCUIL from "@/utils/helpers/validateCUIL";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";
import CargaAgentesCuenta from "../../../../components/modules/cuotas/agenteCuenta/CargaAgentesCuenta.vue";
import FormasPagoAgente from "../../../../components/modules/cuotas/agenteCuenta/FormasPagoAgente.vue";
import CuentaCorrienteAgente from "../../../../components/modules/cuotas/agenteCuenta/CuentaCorrienteAgente.vue";
import RelacionesGrupoFamiliar from "../../../../components/modules/cuotas/agenteCuenta/RelacionesGrupoFamiliar.vue";
import AlicuotasPercibidasAgenteCuenta from "../../../../components/modules/cuotas/agenteCuenta/AlicuotaPercibidaAgenteCuenta.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "GestionAgenteCuenta",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    CargaAgentesCuenta,
    FormasPagoAgente,
    CuentaCorrienteAgente,
    RelacionesGrupoFamiliar,
    AlicuotasPercibidasAgenteCuenta,
    Ayuda
  },

  data: vm => ({
    title: "Gestión de agentes de cuenta",
    filtersApplyed: [],
    titleDelete: "¿Eliminar agente?",
    rules: rules,
    formaPagoIcon: "mdi-cash-multiple",
    cuentaCorrienteIcon: "mdi-card-account-details",
    cuentaCorrienteIcon2: "mdi-account-cash",
    grupoFamiliarIcon: enums.icons.GROUP,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    percentIcon: enums.icons.PERCENT,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    calendarIcon: enums.icons.CALENDAR,
    optionCode: enums.webSiteOptions.GESTION_AGENTES_CUENTA,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    search: "",
    showFilters: true,
    isFormValid: false,
    resultAgentesCuenta: [],
    loading: false,
    dniAgenteCuenta: "",
    idAgenteCuenta: "",
    nombreAgenteCuenta: "",
    cuilOrCuit: "",
    canOpenAlicuotasPercepcion: false,
    allowedActions: null,
    showDeleteModal: false,
    idToDelete: null,
    agectaNom: null,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    canOpenFormasPago: false,
    canOpenCuentaCorriente: false,
    canOpenGrupoFamiliar: false,
    modalFormasPagoAgente: false,
    modalCuentaCorrienteAgente: false,
    modalGrupoFamiliar: false,
    headers: [
      {
        text: "Número de agente",
        sortable: false,
        value: "agectaId"
      },
      {
        text: "Persona o razón social",
        sortable: false,
        value: "agectaNom"
      },
      {
        text: "Dirección",
        sortable: false,
        value: "direccion"
      },

      {
        text: "Condición de IVA",
        sortable: false,
        value: "ivaNom"
      },
      {
        text: "Forma de pago",
        sortable: false,
        value: "fPagoNom"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end"
      }
    ],
    modalAlicuotaPercibidaAgente: false,
    filtersFromEdit: []
  }),
  created() {
    if (this.$route.params.editAgente) {
      this.filtersFromEdit = this.$store.state.filtersAgenteCuenta;
      ({
        agectaNombre: this.nombreAgenteCuenta,
        agectaId: this.idAgenteCuenta,
        dNI: this.dniAgenteCuenta,
        cUIL: this.cuilOrCuit
      } = this.filtersFromEdit);
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
      this.applyFilters();
    }
    if (this.$route.params.nuevoAgente) {
      this.nombreAgenteCuenta = this.$route.params.datosAgente.nombre;
      this.idAgenteCuenta = this.$route.params.datosAgente.id;
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
      this.applyFilters();
    }
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getAgentesCuenta: "agenteCuenta/getAgentesCuenta",
      saveAgenteCuenta: "agenteCuenta/saveAgenteCuenta",
      setAgenteCuentaByBenGrId: "agenteCuenta/setAgenteCuentaByBenGrId",
      getHistorialAgentes: "agenteCuenta/getHistorialAgentes",
      deleteAgenteCuenta: "agenteCuenta/deleteAgenteCuenta",
      fetchAgentesByParams: "agenteCuenta/fetchAgentesByParams",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_AGENTE:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_AGENTE:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_AGENTE:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.FORMAS_PAGO_AGENTE:
            this.canOpenFormasPago = true;
            break;
          case enums.modules.adminSistema.permissions.CUENTA_CORRIENTE_AGENTE:
            this.canOpenCuentaCorriente = true;
            break;
          case enums.modules.adminSistema.permissions.RELACION_GRUPO_FAMILIAR:
            this.canOpenGrupoFamiliar = true;
            break;
          case enums.modules.adminSistema.permissions
            .ALICUOTAS_PERCIBIDAS_AGENTE:
            this.canOpenAlicuotasPercepcion = true;
            break;
          default:
            break;
        }
      });
    },
    deleteAgente(x) {
      this.showDeleteModal = true;
      this.idToDelete = x;
    },
    async confirmDelete() {
      const response = await this.deleteAgenteCuenta(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    validarCUIL(cuil) {
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async applyFilters() {
      this.loading = true;
      const filters = {
        agectaNombre: this.nombreAgenteCuenta,
        agectaId: this.idAgenteCuenta,
        dNI: this.dniAgenteCuenta,
        cUIL: this.cuilOrCuit
      };
      this.customizeFiltersApplied();
      const data = {
        agectaNombre: this.nombreAgenteCuenta,
        agectaId: this.idAgenteCuenta,
        dNI: this.dniAgenteCuenta
          ? this.dniAgenteCuenta.replaceAll(".", "")
          : this.dniAgenteCuenta,
        cUIL: this.cuilOrCuit
          ? this.cuilOrCuit.replaceAll("-", "")
          : this.cuilOrCuit
      };
      try {
        const response = await this.fetchAgentesByParams(data);
        this.resultAgentesCuenta = response;
        this.$store.state.filtersAgenteCuenta = filters;
        if (this.resultAgentesCuenta.length > 0) {
          this.showFilters = false;
          this.showFiltersSelected = true;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      if (this.dniAgenteCuenta) {
        this.filtersApplyed.splice(0, 1, {
          key: "dni",
          label: "N° de documento",
          model: this.dniAgenteCuenta
        });
      }
      if (this.idAgenteCuenta) {
        this.filtersApplyed.splice(1, 1, {
          key: "agectaId",
          label: "N° agente de cuenta",
          model: this.idAgenteCuenta
        });
      }
      if (this.nombreAgenteCuenta) {
        this.filtersApplyed.splice(2, 1, {
          key: "nombre",
          label: "Nombre",
          model: this.nombreAgenteCuenta.toUpperCase()
        });
      }
      if (this.cuilOrCuit) {
        this.filtersApplyed.splice(3, 1, {
          key: "cuil",
          label: "CUIL/CUIT",
          model: this.cuilOrCuit
        });
      }
    },
    goToEditAgenteCuenta(id) {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({
        name: "CargaAgentesCuenta",
        params: { agectaId: id }
      });
    },
    toggleModalFormasPagoAgente(id, nombre) {
      this.modalFormasPagoAgente = !this.modalFormasPagoAgente;
      this.agectaId = id;
      this.agectaNom = nombre;
      if (this.modalFormasPagoAgente === false) this.applyFilters();
    },
    toggleModalRelacionGrupoFamiliar(id, nombre) {
      this.modalGrupoFamiliar = !this.modalGrupoFamiliar;
      this.agectaId = id;
      this.agectaNom = nombre;
    },
    toggleModalCuentaCorrienteAgente(id, nombre) {
      this.modalCuentaCorrienteAgente = !this.modalCuentaCorrienteAgente;
      this.agectaId = id;
      this.agectaNom = nombre;
    },
    toggleModalAlicuotaPercibidaAgente(id, nombre) {
      this.modalAlicuotaPercibidaAgente = !this.modalAlicuotaPercibidaAgente;
      this.agectaId = id;
      this.agectaNom = nombre;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
