<template>
  <v-card>
    <v-card>
      <v-container>
        <v-toolbar flat>
          <v-row class="pb-0">
            <v-col cols="12" md="12" align="start" class="pt-0">
              <v-card-title class="pl-1 primary--text">{{
                title
              }}</v-card-title>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-row>
          <v-col cols="12" md="10" class="py-0" @click="showFilters = true">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
          <v-col cols="12" md="2" class="" v-show="!showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text "
                  @click.stop="closeOpenFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card flat>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            transition="fade-transition"
            ref="cuenta-corriente-filters-form"
            id="cuenta-corriente-filters-form"
            @submit.prevent="applyFilters()"
          >
            <v-row>
              <v-col cols="12" md="3" class="py-0">
                <!-- Comprobantes desde -->
                <v-menu
                  ref="menu"
                  v-model="menuDesde"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pt-2"
                      v-model="fechaDesdeTextField"
                      label="Comprobantes desde"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hint="formato DD/MM/AAAA"
                      @keydown.tab="menuDesde = false"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      @blur="
                        [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                      "
                      @change="
                        [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                      "
                      clearable
                      dense
                      outlined
                      autocomplete="off"
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaDesdeTextField)) <=
                            new Date(parseDateToIso(fechaHastaTextField)) ||
                            'Formato incorrecto'
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fechaDesde"
                    no-title
                    scrollable
                    @change="[(fechaDesdeTextField = formatDate(fechaDesde))]"
                    @input="menuDesde = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <!-- Comprobantes hasta -->

                <v-menu
                  ref="menu2"
                  v-model="menuHasta"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pt-2"
                      v-model="fechaHastaTextField"
                      label="Comprobantes hasta"
                      :append-icon="calendarIcon"
                      @keydown.tab="menuHasta = false"
                      v-mask="'##/##/####'"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      hint="formato DD/MM/AAAA"
                      @blur="
                        [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                      "
                      @change="
                        [
                          (fechaHasta = parseDateToIso(fechaHastaTextField)),
                          changeSaldoHastaLabel(fechaHastaTextField)
                        ]
                      "
                      clearable
                      dense
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaDesdeTextField)) <=
                            new Date(parseDateToIso(fechaHastaTextField)) ||
                            'Formato incorrecto'
                        )
                      "
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fechaHasta"
                    no-title
                    scrollable
                    @change="
                      [
                        (fechaHastaTextField = formatDate(fechaHasta)),
                        changeSaldoHastaLabel(fechaHastaTextField)
                      ]
                    "
                    @input="menuHasta = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-switch
                  v-model="incluirRelacionados"
                  label="Incluir comprobantes relacionados"
                  dense
                  outlined
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-show="showFilters"
                      class="primary--text "
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      size="28"
                      @click="resetForm"
                    >
                      {{ clearFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>Limpiar filtros</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  elevation="2"
                  small
                  :disabled="!isFormValid"
                  type="submit"
                  form="cuenta-corriente-filters-form"
                >
                  Aplicar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-container>
    </v-card>
    <v-card flat>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          item-key="compId"
          no-data-text="No hay datos para las fechas ingresadas"
          :items="comprobantesDeuda"
          :loading="loading"
          calculate-widths
          loading-text="Cargando datos"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Listado de comprobantes</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="seeComprobante(item.compId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver comprobante</span>
              <v-dialog
                v-if="modalCmp === true"
                v-model="modalCmp"
                max-width="80%"
              >
                <DetalleComprobante
                  :compId="compId"
                  @closeModalComprobante="closeModalComprobante"
                />
              </v-dialog>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="
                    toggleComprobantesCancelatorios(
                      item.compId,
                      item.numeroComp
                    )
                  "
                >
                  {{ comprobanteCancelatorioIcon }}
                </v-icon>
              </template>
              <span>Ver comprobantes cancelatorios</span>
              <v-dialog
                v-if="modalComprobanteCancelatorio"
                v-model="modalComprobanteCancelatorio"
                max-width="80%"
              >
                <ComprobantesCancelatorios
                  :compId="compId"
                  :comprobantesCancelatorios="comprobantesCancelatorios"
                  :numeroComprobante="numeroComprobante"
                  @closeModalComprobantesCancelatorios="
                    closeModalComprobantesCancelatorios
                  "
                />
              </v-dialog>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
      <v-container fluid>
        <v-data-table
          :headers="headersCompsSinRelacionar"
          item-key="compId"
          no-data-text="No hay datos para las fechas ingresadas"
          :items="comprobantesSinRelacionar"
          :loading="loading"
          calculate-widths
          loading-text="Cargando datos"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Listado de comprobantes sin relacionar</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="seeComprobanteSinRelacionar(item.compId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver comprobante</span>
              <v-dialog
                v-if="modalCmpSinRelacionar === true"
                v-model="modalCmpSinRelacionar"
                max-width="80%"
              >
                <DetalleComprobante
                  :sinRelacionar="sinRelacionar"
                  :compId="compIdSinRelacionar"
                  @closeModalComprobante="closeModalComprobante"
                />
              </v-dialog>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
      <v-container class="pt-0 mt-0">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              :label="saldosHastaLabel"
              v-model="totalSaldosHasta"
              dense
              outlined
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="8" class="text-right">
            <v-btn outlined @click="closeModal()">
              Cerrar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditComprobante from "./EditComprobante.vue";
import { mapActions } from "vuex";
import Template from "../../../../views/modules/template/template.vue";
import DetalleComprobante from "@/components/modules/cuotas/devengamientos/DetalleComprobante.vue";
import ComprobantesCancelatorios from "./ComprobantesCancelatorios.vue";

export default {
  components: {
    FiltersSelected,
    EditComprobante,
    Template,
    DetalleComprobante,
    ComprobantesCancelatorios
  },
  name: "CuentaCorrienteAgente",
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: null }
  },
  directives: { mask },
  data() {
    return {
      title: `Cuenta corriente del agente: ${this.agectaNom}`,
      saldosDesdeLabel: ``,
      saldosHastaLabel: ``,
      totalSaldosHasta: null,
      seeIcon: enums.icons.SEE,
      comprobanteCancelatorioIcon: "mdi-file-cancel",
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      searchIcon: enums.icons.SEARCH,
      modalCmpSinRelacionar: false,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      incluirRelacionados: false,
      compIdSinRelacionar: null,
      expand: false,
      numeroComprobante: null,
      isFormValid: false,
      comprobantesDeuda: [],
      comprobantesCancelatorios: [],
      comprobantesSinRelacionar: [],
      showFilters: true,
      showFiltersSelected: false,
      filtersApplied: [],
      loading: false,
      modalCmp: false,
      modalComprobanteCancelatorio: false,
      compId: null,
      fechaDesde: null,
      fechaDesdeTextField: null,
      menuDesde: false,
      fechaHasta: null,
      fechaHastaTextField: null,
      menuHasta: false,
      modalEditComprobante: false,
      sinRelacionar: false,
      idCuentaCorriente: null,
      allComprobantes: [],
      headersCompsSinRelacionar: [
        {
          text: "Tipo de comprobante",
          align: "start",
          sortable: false,
          value: "tipoComp"
        },
        {
          text: "Número",
          sortable: false,
          value: "numeroComp"
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Importe sin relacionar",
          sortable: false,
          value: "total",
          align: "end"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      headers: [
        {
          text: "Tipo de comprobante",
          align: "start",
          sortable: false,
          value: "tipoComp"
        },
        {
          text: "Número",
          sortable: false,
          value: "numeroComp"
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Vencimiento",
          sortable: false,
          value: "fechaVencimiento"
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end"
        },
        {
          text: "Cancelado",
          sortable: false,
          value: "cancelado",
          align: "end"
        },
        {
          text: "Saldo",
          sortable: false,
          value: "saldo",
          align: "end"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ]
    };
  },
  created() {
    this.getComprobantesCtaCte();
  },
  watch: {
    allComprobantes(val) {
      if (val.length > 0) {
        this.totalSaldosHasta = this.allComprobantes[0].saldoPendienteTotal;
      } else {
        this.totalSaldosHasta = 0;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchComprobantesCtaCte: "configuracion/fetchComprobantesCtaCte",
      setAlert: "user/setAlert"
    }),

    deleteComprobante(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    changeSaldoHastaLabel(fecha) {
      this.saldosHastaLabel = `Saldo pendiente al ${fecha}`;
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const data = {
        agectaId: this.agectaId,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        incluirRelacionados: this.incluirRelacionados
      };
      try {
        const response = await this.fetchComprobantesCtaCte(data);
        this.allComprobantes = response;
        this.comprobantesDeuda = response.filter(
          x =>
            x.compId != 0 &&
            x.esCancelatorio === false &&
            x.sinRelacionar === false
        );
        this.comprobantesCancelatorios = response.filter(
          x => x.esCancelatorio === true && x.sinRelacionar === false
        );
        this.comprobantesSinRelacionar = response.filter(
          x => x.sinRelacionar === true
        );
        this.$store.state.filters = data;
        this.closeOpenFilters();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.fechaDesdeTextField) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesde",
          label: "Comprobantes desde",
          model: this.fechaDesdeTextField
        });
      }
      if (this.fechaHastaTextField) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHasta",
          label: "Comprobantes hasta",
          model: this.fechaHastaTextField
        });
      }
      if (this.incluirRelacionados) {
        const incluirCL = "Si";
        this.filtersApplied.splice(2, 1, {
          key: "incluirRelacionados",
          label: "Incluye comprobantes relacionados",
          model: incluirCL
        });
      } else {
        const incluirCL = "No";
        this.filtersApplied.splice(2, 1, {
          key: "incluirRelacionados",
          label: "Incluye comprobantes relacionados",
          model: incluirCL
        });
      }
      this.closeOpenFilters;
    },
    resetForm() {
      this.$refs["cuenta-corriente-filters-form"].reset();
      this.filtersApplied = [];
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getComprobantesCtaCte() {
      // fecha de hoy
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      // seis meses atras
      let sixMonths = new Date(today.setMonth(today.getMonth() - 6));
      let sixMonthsAño = sixMonths.getFullYear();
      let sixMonthsMes = sixMonths.getMonth() + 1;
      let sixMonthsDia = sixMonths.getDate();
      sixMonthsDia < 10 ? (sixMonthsDia = `0${sixMonthsDia}`) : sixMonthsDia;
      sixMonthsMes < 10 ? (sixMonthsMes = `0${sixMonthsMes}`) : sixMonthsMes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaTextField = this.formatDate(this.fechaHasta);
      this.fechaDesde = `${sixMonthsAño}-${sixMonthsMes}-${sixMonthsDia}`;
      this.fechaDesdeTextField = this.formatDate(this.fechaDesde);
      this.changeSaldoHastaLabel(this.fechaHastaTextField);
      this.applyFilters();
    },
    closeModal() {
      this.$emit("toggleModalCuentaCorrienteAgente");
    },
    seeComprobante(compId) {
      this.modalCmp = true;
      this.compId = compId;
    },
    seeComprobanteSinRelacionar(compId) {
      this.sinRelacionar = true;
      this.modalCmpSinRelacionar = true;
      this.compIdSinRelacionar = compId;
    },
    closeModalComprobante() {
      this.modalCmp = false;
      this.modalCmpSinRelacionar = false;
    },
    toggleComprobantesCancelatorios(compId, numero) {
      this.modalComprobanteCancelatorio = true;
      this.compId = compId;
      this.numeroComprobante = numero;
    },
    closeModalComprobantesCancelatorios() {
      this.modalComprobanteCancelatorio = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
