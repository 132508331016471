<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="compId"
            hide-default-footer
            :items="comprobantes"
            :loading="loading"
            loading-text="Cargando datos"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="seeComprobante(item.compId)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
                <v-dialog
                  v-if="modalCmp === true"
                  v-model="modalCmp"
                  max-width="80rem"
                >
                  <DetalleComprobante
                    :esCancelatorio="esCancelatorio"
                    :compId="compCancId"
                    @closeModalComprobante="closeModalComprobante"
                  />
                </v-dialog>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import DetalleComprobante from "@/components/modules/cuotas/devengamientos/DetalleComprobante.vue";

export default {
  components: { DetalleComprobante },
  name: "ComprobantesCancelatorios",
  props: {
    compId: { type: Number, required: false, default: null },
    numeroComprobante: { type: String, required: false, default: "" },
    comprobantesCancelatorios: { type: Array, required: false, default: [] }
  },
  data() {
    return {
      title: `Comprobantes cancelatorios de ${this.numeroComprobante}`,
      rules: rules,
      comprobantes: [],
      seeIcon: enums.icons.SEE,
      compCancId: null,
      loading: false,
      esCancelatorio: false,
      modalCmp: false,
      headers: [
        {
          text: "Tipo de comprobante",
          align: "start",
          sortable: false,
          value: "tipoComp"
        },
        {
          text: "Número",
          sortable: false,
          value: "numeroComp"
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Importe cancelado",
          sortable: false,
          value: "total",
          align: "end"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ]
    };
  },
  created() {
    this.setComprobantesCancelatorios();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getComprobantesCancelatorios: "agenteCuenta/getComprobantesCancelatorios"
    }),
    async setComprobantesCancelatorios() {
      this.loading = true;
      const data = {
        compId: this.compId,
        compCancIds: this.comprobantesCancelatorios.map(x => x.compId)
      };
      const comprobantesCanc = await this.getComprobantesCancelatorios(data);
      const comprobantesCancIds = comprobantesCanc.map(x => x.compCancId);
      this.comprobantes = this.comprobantesCancelatorios.filter(x =>
        comprobantesCancIds.includes(x.compId)
      );
      this.loading = false;
    },
    closeModal() {
      this.$emit("closeModalComprobantesCancelatorios");
    },
    seeComprobante(compCancId) {
      this.esCancelatorio = true;
      this.modalCmp = true;
      this.compCancId = compCancId;
    },
    closeModalComprobante() {
      this.modalCmp = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
